/* You can add global styles to this file, and also import other style files */
// @import url("https://fonts.googleapis.com/css2?family=Reem+Kufi+Fun:wght@400..700&display=swap");
//@import url("https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap");
@import "assets/fonts/GillSans/stylesheet.css";

* {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  --mat-dialog-transition-duration: 0ms !important;
  overflow: hidden;

  ::-moz-focus-outer,
  ::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  transition: opacity 2.5s;
  visibility: visible;
  opacity: 1;
  height: 100vh;
  position: relative;
  font-family: 'Gill Sans', sans-serif !important;
  background-color: #263038;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

blockquote {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
}

fieldset {
  border: none;
  padding: 0;
}

button {
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.coin {
  position: absolute;
  z-index: 11;
  transition: all 1s;
  pointer-events: none;
  font-size: 34px;
  font-weight: bold;
  color: #fff;
  // text-shadow: 0 0 3px #3c3a3a, 0 0 5px #5f5f77;

  &.hide {
    transform: translateY(-100px);
    opacity: 0;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 0 !important;
}

.cdk-overlay-pane {
  max-width: 100% !important;
  border-radius: 0 !important;
}

.cdk-overlay-container .mat-dialog-container {
  background: none;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}

@keyframes scale {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(0.95);
  }
}

@import "assets/styles/button";
