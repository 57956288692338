._btn {
  color: #eee;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  background: round;

  &.red {
    background-image: url('/assets/img/ui/red.webp');
  }

  &.gray {
    background-image: url('/assets/img/ui/gray.webp');
  }
}
