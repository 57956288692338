@font-face {
    font-family: 'GillSans';
    src: url('GillSansCondBlackC.eot');
    src: local('GillSansCondBlackC'),
        url('GillSansCondBlackC.eot?#iefix') format('embedded-opentype'),
        url('GillSansCondBlackC.woff2') format('woff2'),
        url('GillSansCondBlackC.woff') format('woff'),
        url('GillSansCondBlackC.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'GillSans';
    src: url('GillSansC.eot');
    src: local('GillSansC'),
        url('GillSansC.eot?#iefix') format('embedded-opentype'),
        url('GillSansC.woff2') format('woff2'),
        url('GillSansC.woff') format('woff'),
        url('GillSansC.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

